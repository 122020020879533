import React from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { useEvalState } from "@/util/eval-state.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function Match() {
  const {
    parameters: [gameUsername, gameId],
  } = useRoute();
  const { t } = useTranslation();
  const {
    rocket: { profiles, matches },
  } = useSnapshot(readState);
  const profile = useEvalState(profiles[gameUsername]);
  const match = useEvalState(matches[gameId]);
  if (!profile || !match)
    return <div>{t("", "Player or match is not valid, nice try bud")}</div>;
  return (
    <div>
      <div>{t("", "Match loaded, nice")}</div>
      <div>{match.gameId}</div>
      <div>{match.periodId}</div>
      <div>{match.id}</div>
      <div>{match.gameStartTime}</div>
    </div>
  );
}

export function meta([gameUsername = "Unknown"]) {
  return {
    title: [
      "rocket:meta.match.title",
      "{{gameUsername}}'s Match Stats – Blitz Rocket League",
      { gameUsername },
    ],
    description: [
      "rocket:meta.match.description",
      "Match Stats for {{gameUsername}}",
      { gameUsername },
    ],
  };
}
